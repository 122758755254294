<script setup lang="ts">
import { FieldParagraphInfoBoxFieldInfoBox, ParagraphInfoBox } from '~/api/graphql/generated'

const props = defineProps<{
  entity: ParagraphInfoBox
}>()

const item = {
  title: (props.entity.fieldInfoBox as FieldParagraphInfoBoxFieldInfoBox).entity?.fieldTitle,
  image: (props.entity.fieldInfoBox as FieldParagraphInfoBoxFieldInfoBox).entity?.fieldIcon?.url,
  text: (props.entity.fieldInfoBox as FieldParagraphInfoBoxFieldInfoBox).entity?.fieldText?.processed,
}
</script>

<template>
  <Section v-animate="{ animation: 'vertical-reveal' }">
    <div class="t-10 t-o-1 tl-7 tl-o-0 ds-o-1">
      <div class="info-box" :class="{ 'has-icon': item.image }">
        <img v-if="item.image" :src="item.image" loading="lazy" />
        <div>
          <h4>{{ item.title }}</h4>
          <div class="description text-xsmall" v-html="item.text" />
        </div>
      </div>
    </div>
  </Section>
</template>
<style scoped lang="scss">
.info-box {
  padding: 24px;
  background-color: var(--c-background-medium);
  &.has-icon {
    display: grid;
    grid-template-columns: 30px 1fr;
    gap: 24px;
    align-items: center;
  }
  :deep(.description) {
    * {
      margin: 0;
      color: var(--c-primary);
      a {
        &:hover {
          color: var(--c-blue-rollover);
        }
      }
    }
  }
}
</style>
